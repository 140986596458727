import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { isUserLoggedIn, pushDataLayerEventHandler, showCustomToast } from '@/utils/utils'
import IconoGraphy from '@/components/Common/IconoGraphy'
import styles from "./style.module.scss";
import { useRouter } from 'next/router';
import CustomToast from '../CustomToast';
import { saveUnsaveAPI } from '@/services/saveunsaveAPI';


const FollowButton = (props) => {
    const { hasFollowed, auctionTypeStatus, auctionId, btnType, assetSequence, onUpdateFollowerCount, followData, pageType, GdpId, auctionType, FollowId, refetchFollowedAuctions, showClass, groupId = '', updateAuctionStatus= null } = props
    const { t, lang } = useTranslation('translations-auction')
    const [followbellClick, setfollowbellClick] = useState(null)
    const isFollowing = followbellClick ?? hasFollowed 
    const router = useRouter();
    let headerText ;
    if (assetSequence) {
        headerText = isFollowing ? `${t('followAuctions.unfollowedGdpText', {assetSequence})}` : `${t('followAuctions.followedGdpText', {assetSequence})} `
    } else {
        headerText = isFollowing ? t('followAuctions.unfollowedText') : t('followAuctions.followedText')
    }
    const subText = isFollowing ? t('followAuctions.unfollowedSubText') : t('followAuctions.followedSubText')
    const LoginFollowId = router.query.followAuctionId

    const onFollowAuction = async () => {
        const onFollowbtn = btnType === 'bell' ? 'follow_notification' : 'follow_button'
        const onunFollowbtn = btnType === 'bell' ? 'unfollow_notification' : 'unfollowing_button'
        const followUnfollow = isFollowing ? onunFollowbtn : onFollowbtn
        const sellerTypeFollowData = followData?.sellerType ?  followData?.sellerType : 'Infath'
        const sellerType = followData?.seller === 'au-wasalt' ? 'Wasalt' :  sellerTypeFollowData
        const SellerAuctionType = auctionType === 'wasalt-online' ? 'Wasalt' : 'Infath'
        const auctionPageType = pageType === 'Profile' ? SellerAuctionType : sellerType
        pushDataLayerEventHandler(window, lang, followUnfollow, pageType, {
            group_auction_id: GdpId,
            auction_id: FollowId,
            status: auctionTypeStatus,
            seller_type: auctionPageType,
        })
    }

    const handleFollow = async() => {
        onFollowAuction()
        if(LoginFollowId) {
            handleRemoveQueryParam()
        }
        const entityId = [
            {
                id: LoginFollowId || auctionId,
                propIdentityType: 95,
            },
        ]

        const actionUrl = isFollowing ? 'unsaveAuction' : 'saveAuction';
        const apiResponse = await saveUnsaveAPI(actionUrl, entityId, lang, 'followed_auction', groupId);
        if (refetchFollowedAuctions) {
            refetchFollowedAuctions()
        }
         if (!apiResponse?.data?.status) {
            if (updateAuctionStatus) {
                updateAuctionStatus(apiResponse?.data)
            } else {
                showCustomToast(
                    <CustomToast
                      showIcon='warning'
                      headerText={apiResponse?.data?.message}
                    />,
                  'oops',
                  lang
                )
            }
            return;
         }
       
        if (apiResponse?.data?.status) {
            onUpdateFollowerCount && onUpdateFollowerCount(prevState => isFollowing ? parseInt(prevState) - 1 : parseInt(prevState) + 1)
            showCustomToast(
                <>
                    <CustomToast
                        showIcon='follow_bell'
                        headerText={headerText}
                        descriptiveText={subText}
                    />
                </>,
                'bid-success',
                lang
            )
        }
        setfollowbellClick(!isFollowing)
    }

    const handleRemoveQueryParam = () => {
        // Get the current query parameters
        const { followAuctionId, ...currentQueryParams } = router.query;
    
        // Use router.replace to replace the URL without the specified query parameter
        router.replace({
          pathname: router.pathname,
          query: currentQueryParams,
        }, undefined, { scroll: false });
    };

    useEffect(() => {
        if (isUserLoggedIn() && LoginFollowId && auctionId && LoginFollowId === auctionId) {
            handleFollow()
        }
    }, [])
    
    useEffect(() => {
      setfollowbellClick(hasFollowed)
    }, [hasFollowed])

    const followClick = async (event) => {
        event.preventDefault()
        if (!isUserLoggedIn()) {
            router.push(`/user/login?followAuction=${auctionId}&redirectUrl=${router.asPath}`)
            return 
        }
        handleFollow()
    }
    
    const followBellIcon = () => {
        return (auctionTypeStatus !== 'past' && auctionTypeStatus !== 'cancel' ) && (
            <span className={styles.followIcon} onClick={followClick}>
                <IconoGraphy
                    iconClass={isFollowing ? 'icon-notification-filled' : 'icon-notification'}
                    iconColor={isFollowing ? 'color_purple' : 'color_gray_light'}
                    fontSize={'f_16'}
                />
            </span>
        )
    }

    const followBtn = () => {
        return (auctionTypeStatus !== 'past' && auctionTypeStatus !== 'cancel' ) &&
        <button className={`${styles.viewdetailBtn} ${showClass && styles[showClass]}`} onClick={followClick}>
            <span className={styles.bidIcon}>
                    <IconoGraphy
                        iconClass={isFollowing ? 'icon-notification-filled' : 'icon-notification'}
                        iconColor={'color_purple'}
                        fontSize={'f_16'}
                    />                
            </span>
                   {isFollowing ? t('followAuctions.following') : t('followAuctions.follow')}
                
        </button>

    }
    return (
    <>
     {btnType === 'bell' ? followBellIcon() : followBtn()  }
    </>
           

    )
}

export default FollowButton;
